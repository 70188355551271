import React, { useState } from 'react';
import Modal from '@/components/Modal';
import Headline from '@/components/Headline';
import Label from '@/components/Label';
import Input from '@/components/Input';
import Text from '@/components/Text';
import Button from '@/components/Button';

import Countdown from '@/components/Countdown';
import { ButtonWrapper, CountDownWrapper, FormWrapper, SubscribeWrapper } from '../styled';
import { LOCALSTORAGE_DISMISS_REMINDER_SUBSCRIBE, PROMOTION_SECOND_ROUND_DATE_START } from '@/constants/globals';
import translations from '../constants/translations';


const SubscribeForm = () => {
    const isModalDismissed = localStorage.getItem( LOCALSTORAGE_DISMISS_REMINDER_SUBSCRIBE );

    const handleCloseModal = () => {
        setToggleModal( !isModalOpen );
    };
    const [ isModalOpen, setToggleModal ] = useState( true );
    const handleDismissSubscription = () => {
        localStorage.setItem( LOCALSTORAGE_DISMISS_REMINDER_SUBSCRIBE, 'correct' );
        setToggleModal( !isModalOpen );
    };

    return (
        ( isModalDismissed !== 'correct' && <Modal
                open={ isModalOpen }
                onClose={ handleCloseModal }
                closeOnOverlayClick={ false }
                closeOnEsc={ true }
                customStyles={ {
                    closeIcon: {
                        display: 'none',
                    },
                    modal: { maxWidth: '90%' },
                } }

            >
                <SubscribeWrapper>
                    <Headline subHeading={ translations.subscribeIntroText03 }>
                        { translations.subscribeHeadline }
                    </Headline>
                    <CountDownWrapper>
                        <Countdown variant="dark" dateEnd={ PROMOTION_SECOND_ROUND_DATE_START }/>
                    </CountDownWrapper>
                    <Text size="medium" align="center">
                        { translations.subscribeIntroText01 }
                        <br/>
                        { translations.subscribeIntroText02 }
                        <br/>
                        <br/>
                    </Text>
                    <FormWrapper
                        action="https://shop.us21.list-manage.com/subscribe/post?u=5d2a8655def2ade97b8df2cf4&amp;id=70c797f059&amp;f_id=00fc80e6f0"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        className="validate"
                        target="_self"
                    >
                        <Label htmlFor="mce-EMAIL">
                            Email: <span className="asterisk">*</span>
                        </Label>
                        <Input
                            type="email"
                            name="EMAIL"
                            className="required email"
                            id="mce-EMAIL"
                            required
                            defaultValue=""
                        />
                        <div className="mc-field-group">
                            <Label htmlFor="mce-FNAME">Imię:</Label>
                            <Input type="text" name="FNAME" className="text" id="mce-FNAME"
                                   defaultValue=""/>
                        </div>
                        <div hidden>
                            <input type="hidden" name="tags" value="2987019"/>
                        </div>
                        <div id="mce-responses" className="clear">
                            <div className="response" id="mce-error-response"
                                 style={ { display: 'none' } }></div>
                            <div className="response" id="mce-success-response"
                                 style={ { display: 'none' } }></div>
                        </div>
                        <div aria-hidden="true" style={ { position: 'absolute', left: '-5000px' } }>
                            <input
                                type="text"
                                name="b_5d2a8655def2ade97b8df2cf4_70c797f059"
                                tabIndex="-1"
                                defaultValue=""
                            />
                        </div>
                        <ButtonWrapper>
                            <Button
                                variant="action"
                                type="submit"
                                name="subscribe"
                                id="mc-embedded-subscribe"
                                className="button"
                            >
                                { translations.subscribeButtonSubmit }
                            </Button>
                            <Button
                                variant="text"
                                onClick={ handleDismissSubscription }
                            >
                                { translations.subscribeButtonDismiss }
                            </Button>
                        </ButtonWrapper>
                    </FormWrapper>
                </SubscribeWrapper>
            </Modal>
        ) );
};


export default SubscribeForm;
