import styled from 'styled-components';

export const Input = styled.input`
    font-size: ${ ( { theme } ) => theme.fontSizeXS };
    background: ${ ( { theme } ) => theme.ui01 };
    height: 40px;
    width: 100%;
    margin: ${ ( { theme } ) => theme.spacing07 } 0;
    padding: ${ ( { theme } ) => theme.spacing06 };
    color: ${ ( { theme } ) => theme.text01 };
    border-radius: ${ ( { theme } ) => theme.borderRadiusS };
    border: 1px solid #DDD;
`;

export default Input;
