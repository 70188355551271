import { globals } from '@/constants';
import { getBundlePrice } from '@/utils';
export const translations = {
    subscribeHeadline: 'Nie przegap szansy!',
    subscribeIntroText01: `Podaj swój adres email, a my przypomnimy Ci`,
    subscribeIntroText02: `o nadchodzącej promocji, tym razem już nie przegapisz okazji!`,
    subscribeIntroText03: `Do kolejnej akcji zostało niewiele czasu!`,
    subscribeButtonSubmit: 'Zapisz mnie',
    subscribeButtonDismiss: 'Nie pokazuj więcej',
};

export default translations;
